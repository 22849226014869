<template>
  <ApartmentsMap :data="data"/>
  <house-finder/>
</template>


<script setup>
import ApartmentsMap from "@/components/smartComponents/ApartmentsPlane/ApartmentsPlane";
import HouseFinder from "@/components/smartComponents/HouseFinder/HouseFinder";
import {computed} from "vue";

let props = defineProps({
  contentData: {
    type: Object,
  }
})

let data = computed(() => props.contentData?.data?.projects?.list?.[0])
</script>